import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Bordas = () => (
  <>
    <SEO title="Bordas" />

    <Menu className="menu">
      <Slide left cascade duration={300}>
        <h1 className="menu-title">Bordas</h1>
      </Slide>

      <div className="menu-sizes">
        <p>4 Pdç</p>
        <p>8 Pdç</p>
        <p>12 Pdç</p>
      </div>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Borda Requeijão</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">Grátis</h4>
            <p className="item-price"></p>
            <p className="item-price"></p>
          </div>
        </div>

        <p className="item-desc"></p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Borda Tradicional</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
            <h4 className="item-price">10,00</h4>
            <h4 className="item-price">12,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Sabores: Catupiry, Cheddar, Mussarela, Cream Cheese, Presunto e
          Queijo.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Borda de Vulcão</h4>

          <div className="item-price-wrapper">
            <p className="item-price"></p>
            <h4 className="item-price">12,00</h4>
            <h4 className="item-price">15,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Sabores: Catupiry, Cheddar, Mussarela, Cream Cheese.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Borda Doce</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
            <h4 className="item-price">10,00</h4>
            <h4 className="item-price">12,00</h4>
          </div>
        </div>

        <p className="item-desc">Sabores: Chocolate ou Ninho.</p>
      </Item>
    </Menu>
  </>
);

export default Bordas;
